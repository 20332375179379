import React from "react";
import "./ourStory.css";
import founders from "../../assets/founders.png";

const OurStory = () => {
  return (
    <div className="lax__ourStory section__padding" id="ourstory">
      <h2>Our Story</h2>
      <div className="lax__ourstory-details">
        <div className="lax__ourstory-details_content">
          <p>
            LAX Nightclub is a venue specially dedicated to techno music in the
            Errotaja streets of Helsinki. The venue was founded in 2002 by two
            goodd friends, Anni and Leila. The idea was to create a techno venue
            which will bring techno lovers and artists from Helsinki.
          </p>

          <p>
            Fast forward to today, LAX has turned into a big attraction to many
            techno lovers from all over the world. With frequent international
            DJ's playing in the venue on a weekly bassis, this has turned LAX
            into the European techno hub/heaven.
          </p>
        </div>
        <div className="lax__ourstory-details_image">
          <img src={founders} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
