import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="lax__footer ">
      <div className="lax__footer-top">
        <div className="lax__footer-top_container">
          <h2>LAX NIGHTCLUB</h2>
          <p>
            A wine and cocktail Heaven in the heart of Helsinki. Come enjoy and
            relax with g friends. #goodvibesonly
          </p>
        </div>
        <div className="lax__footer-top_container">
          <h2>Opening hours</h2>
          <p>Monday: 11:00 - 22:00</p>
          <p>Tuesday: 11:00 - 22:00</p>
          <p>Wednesday: 11:00 - 22:00</p>
          <p>Thursday: 11:00 - 22:00</p>
          <p>Friday: 11:00 - 22:00</p>
          <p>Saturday: 11:00 - 22:00</p>
          <p>Sunday: 11:00 - 22:00</p>
        </div>
        <div className="lax__footer-top_container">
          <h2>Yryönkatu 2 Helsinki, 00100</h2>
          <p>Phone: +358 44987 4860</p>
          <p>Email: info@laxbar.fi</p>
        </div>
      </div>
      <div className="lax__footer-sign">
        <p>Designed and built by Brian Simiyu</p>
      </div>
    </div>
  );
};

export default Footer;
