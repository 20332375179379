import React, { useState, useEffect } from "react";
import "./navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const [navbar, setNavbar] = useState(false);
  const changeBg = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBg);

  return (
    <div className={navbar ? "lax__navbar active" : "lax__navbar"}>
      <div className="lax__navbar_logo">
        <h1>
          <a href="#home">LAX NIGTCLUB</a>
        </h1>
      </div>

      <div className="lax__navbar-links">
        <div className="lax__navbar-links_container">
          <p>
            <a href="#details">Details</a>
          </p>
          <p>
            <a href="#ourstory">About us</a>
          </p>
          <p>
            <a href="#amenities">Amenities</a>
          </p>
          <button>
            <a href="#home">Book a Table</a>
          </button>
        </div>
      </div>

      <div className="lax__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#ffffff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#ffffff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="lax__navbar-menu__container-links">
            <p>
              <a href="#home">Details</a>
            </p>
            <p>
              <a href="#ourstory">About us</a>
            </p>
            <p>
              <a href="#amenities">Amenities</a>
            </p>
            <button>
              <a href="#home">Book a Table</a>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
