import React from "react";
import { Navbar } from "../../components";
import heroVideo from "../../assets/hero-video.mp4";

import "./header.css";

const Header = () => {
  return (
    <div className="lax__header">
      <Navbar />

      <video src={heroVideo} autoPlay loop muted />
    </div>
  );
};

export default Header;
