import React from "react";
import "./amenityCard.css";

const AmenityCard = ({ image, title, text }) => {
  return (
    <div className="lax__amenityCard">
      <div className="lax__amenityCard-image">
        <img src={image} alt="Amenity image" />
      </div>

      <div className="lax__amenityCard-container">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default AmenityCard;
