import React from "react";
import { Card } from "../../components";
import "./details.css";

const Details = () => {
  return (
    <div className="lax__details section__padding" id="details">
      <div className="lax__details-main">
        <h2>Amazing techno music, tasty cocktails & good vibes</h2>
        <p>
          The Techno Heaven of Europe in the heart of Helsinki. All are welcome.
        </p>
      </div>
      <div className="lax__details-container">
        <Card
          title="BEST DJS"
          text="Leave the worries of life behind and come spend relaxing moments with friends at LAX Nightclub. We have the best in-house DJs and we sometimes have guest all over the world."
        />
        <Card
          title="LIGHTSHOWS"
          text="We have a dedicated light engineering team dedicated in creating great lightshows as our guests are enjoying their time.Come expereince it and you will live to tell."
        />
        <Card
          title="COCKTAILS"
          text="Explore a wide selection of delicious cocktails available in the bar and we are continuously adding novelties all the time. Our high qualified bartenders will definitely quench your thirst at any day."
        />
        <Card
          title="FOOD"
          text="We also serve food and snacks in the bar. You can get Internationally themed burgers, prim cut steaks and fresh green hearty salads. Imported aged rib-eye and rump steaks served with your choice of seasonal vegetables or fries and salad."
        />
      </div>
    </div>
  );
};

export default Details;
