import React from "react";
import { AmenityCard } from "../../components";
import { billiards, boardGames, darts, jazz } from "./import";
import "./amenities.css";

const Amenities = () => {
  return (
    <div className="lax__amenites section__padding" id="amenities">
      <div className="lax__amenites-container">
        <AmenityCard
          image={jazz}
          title="Entertainment"
          text="We constantly have live bands playing very soothing jazz music creating a very smooth and soothing atmosphere in the bar."
        />

        <AmenityCard
          image={boardGames}
          title="Board games"
          text="Chess, Monopoly, Kimble, trivial pursuit… Sounds familiar? Who plays their cards, their money, their look and shows their skills?"
        />

        <AmenityCard
          image={billiards}
          title="Billiards"
          text="There is a 9-foot pool table available for only € 9.50! For those with a membership card, it is half price. Come pick up yours!"
        />

        <AmenityCard
          image={darts}
          title="Dart"
          text="Who throws the best darts? Measure who is the king of a group of friends and throw a bullseye! Free racing board darts board."
        />
      </div>
    </div>
  );
};

export default Amenities;
